import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
// @mui
import { styled,useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';


// components
import { color } from '@mui/system';
import Page from '../components/Page';
import { loginRequest } from '../authConfig';
import Logo from '../components/Logo';
// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { instance, accounts, inProgress } = useMsal();
  const theme = useTheme();
  const handleLogin = (loginType) => {
    if (loginType === 'popup') {
      instance.loginPopup(loginRequest).catch((e) => {
        // console.log(e);
      });
    } else if (loginType === 'redirect') {
      instance.loginRedirect(loginRequest).catch((e) => {
        // console.log(e);
      });
    }
  };

  // handleLogin("redirect");
  return (
    <Page title="Login">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>

          <Logo 
            imageHeight={200}
            sx={{ mx: 'auto' }}
          />

          <Box
            component="img"
            src="/static/illustrations/login.svg"
            sx={{ height: 260, mt: '-10px', mb: '25px', mx: 'auto' }}
          />

          <Button onClick={() => handleLogin('redirect')} size="large" variant="contained">
            Accedere
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  );
}
