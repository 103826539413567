// ----------------------------------------------------------------------

// Function to detect if the user is on Windows
function isWindows() {
  return navigator.platform.indexOf('Win') > -1;
}

export default function CssBaseline() {
/*   if (isWindows() || true) {
    document.body.classList.add('windows-os');
  } */

  return {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
          fontSize: '0.9rem',
        },
        body: {
          width: '100%',
          height: '100%',
          fontSize: '0.9rem',
        },
        '#root': {
          width: '100%',
          height: '100%',
          fontSize: '0.9rem',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        // Styles for the scrollbar
        // Windows specific scrollbar styles
        '.windows-os *::-webkit-scrollbar': {
          width: '10px',
          height: '10px',
        },
        '.windows-os *::-webkit-scrollbar-track': {
          background: 'rgba(241, 241, 241, 0)',
        },
        '.windows-os *::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '10px',
        },
        '.windows-os *::-webkit-scrollbar-thumb:hover': {
          background: 'rgba(241, 241, 241, 0)',
        },
      },
    },
  };
}
