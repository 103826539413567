import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import React, { useState, useEffect, useRef } from 'react';
import { EventType, InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from './authConfig';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';

// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import Login from "./pages/Login";
// import Location from './utils/location';

// ----------------------------------------------------------------------

const MainContent = () => (
  <div className="App">
    <AuthenticatedTemplate>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
    </AuthenticatedTemplate>

    <UnauthenticatedTemplate>
      <Login />
    </UnauthenticatedTemplate>
  </div>
);
export default function App() {

  const authName = process.env.REACT_APP_AUTH_NAME; 
  const { instance, accounts, inProgress } = useMsal();
  // const { url, prevUrl } = Location();

  useEffect(() => {
    // forgot password
    instance.addEventCallback((message) => {
      // console.log('error login', inProgress, message, EventType.HANDLE_REDIRECT_END);
      if (message.eventType === EventType.LOGIN_FAILURE && message.error.errorMessage.includes('AADB2C90118')) {
        // The user has forgotten their password.
        const forgotPasswordRequest = {
          scopes: loginRequest.scopes,
          authority: `https://${authName}.b2clogin.com/tfp/${authName}.onmicrosoft.com/B2C_1_PASSWORD_RESET`,
        };
        instance.loginRedirect(forgotPasswordRequest);
      } else if (message.eventType === EventType.HANDLE_REDIRECT_END && inProgress === InteractionStatus.None) {
        const loginRequest = {
          scopes: loginRequest.scopes,
          authority: `https://${authName}.b2clogin.com/tfp/${authName}.onmicrosoft.com/B2C_1_SIGN_IN`,
        };
        instance.loginRedirect(loginRequest);
      }
    });
  }, [inProgress, instance]);

  return (
    <ThemeProvider>
      <MainContent />
    </ThemeProvider>
  );
}
